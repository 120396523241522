import { useState, useEffect } from 'react';
import styled from 'styled-components';
// components
import SideBar from 'components/organisms/common/SideBar';
import Footer from 'components/molecules/common/Footer';
// hooks
import useWebview from 'hooks/common/useWebview';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
// styles
import { mediaQuery, palette } from 'cds/styles';

/**
 * 콘텐츠 영역 레이아웃
 * @param {object} props
 * @param {boolean} props.disableFooter
 */
const BodyLayout = ({ disableFooter, children }) => {
  const { isWebview } = useWebview();

  const [isIpadApp, setIsIpadApp] = useState(false);

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  return (
    <>
      <Layout>
        <Left id="left-side">
          <SideBar />
        </Left>
        <Right id="right-side">
          {children}
          {!isWebview && !isIpadApp && <Footer disableFooter={disableFooter} />}
        </Right>
      </Layout>
    </>
  );
};

const Layout = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
`;

const Left = styled.div`
  flex: 0 0 auto;

  ${mediaQuery.large} {
    width: 240px;
  }
`;

const Right = styled.div`
  flex: 0 auto;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  ${mediaQuery.large} {
    max-width: calc(100% - 240px);
  }
`;

export default BodyLayout;
